/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "HelveticaNeueCondensed";
  src: url("/extras/fonts/c6b63be6-6438-4c5e-8bda-0aabe7846d50.eot?#iefix");
  src: url("/extras/fonts/c6b63be6-6438-4c5e-8bda-0aabe7846d50.eot?#iefix") format("eot"), url("/extras/fonts/9516ef74-4fe9-45fe-a09f-493cb9518f71.woff2") format("woff2"), url("/extras/fonts/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.woff") format("woff"), url("/extras/fonts/1eb34627-d796-4531-9e56-d58a15603fcd.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueCondensedBold";
  src: url("/extras/fonts/960546c0-8bc0-4951-94f4-a871c16e8211.eot?#iefix");
  src: url("/extras/fonts/960546c0-8bc0-4951-94f4-a871c16e8211.eot?#iefix") format("eot"), url("/extras/fonts/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"), url("/extras/fonts/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff"), url("/extras/fonts/54838aa7-3cb7-478e-b2a6-14eef3033f22.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeueCondensedBlack";
  src: url("/extras/fonts/dce7bbc3-8e4f-4859-9834-4c768ee3477c.eot?#iefix");
  src: url("/extras/fonts/dce7bbc3-8e4f-4859-9834-4c768ee3477c.eot?#iefix") format("eot"), url("/extras/fonts/f2f49a5e-567d-4dfa-8152-c28c8f60c84e.woff2") format("woff2"), url("/extras/fonts/ea7ce364-9de4-43c3-9b0b-7b26af453629.woff") format("woff"), url("/extras/fonts/37eafb59-e1a4-4636-a437-989cb870c195.ttf") format("truetype");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 45px;
  margin-bottom: 45px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #7B7067;
  color: #7B7067;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #7B7067 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #7B7067;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  line-height: 1.3;
  font-size: 20px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #7B7067;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #7B7067;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #f2f0ef;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #0294B3;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #0294B3;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
video {
  float: left;
  width: 100%;
}
#edit .part video {
  pointer-events: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
}
@media (max-width: 539px) {
  .table {
    font-size: 3.71057514vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(2, 148, 179, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
#home {
  position: absolute;
  left: 2.34375vw;
  top: 2.34375vw;
  width: 17.65625vw;
  z-index: 3;
}
.roothome {
  float: left;
  margin-top: 20px;
  margin-left: 2.34375vw;
  width: 34.84375vw;
  display: none;
}
.cb-layout3 .roothome {
  display: block;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #7B7067;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  line-height: 1.3;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
.wrapper__part {
  float: left;
  width: 100%;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--multimood {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.cb-layout3 .section--multimood {
  display: none;
}
.section--footer {
  background: #7B7067;
}
.normcontent {
  float: left;
  width: 100%;
}
.footcontent {
  float: left;
  width: 100%;
  color: #fff;
  position: relative;
}
.footcontent a {
  color: #CCC7C2;
}
.footcontent a:hover,
.footcontent a:focus {
  color: #fff;
}
.c2 .footcontent {
  padding-top: 150px;
}
.footpart {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.footpart--social {
  margin-top: 0;
}
.footpart--vcard .org {
  display: block;
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
}
.footpart--vcard .adr {
  display: block;
}
.footpart--vcard a {
  color: #fff;
}
.footpart--vcard a:hover,
.footpart--vcard a:focus {
  color: #433d39;
}
.footpart--anfahrt {
  margin-top: 50px;
}
.footpart--certificates,
.footpart--social {
  border-top: 2px dotted #998F86;
  border-bottom: 2px dotted #998F86;
  padding: 30px 0;
}
.footpart--services {
  margin-top: 0;
  padding-top: 30px;
  border-top: 2px dotted #998F86;
}
.footpart--links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footpart--links .dock {
  float: left;
  width: 47%;
}
.cb-layout3 .footpart--links .dock {
  width: 30%;
}
.footpart--links .dock .meta {
  display: block;
  text-transform: uppercase;
  line-height: 1.5;
}
.footpart--services {
  margin-bottom: 70px;
}
.foottitle {
  display: block;
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.0125em;
  padding-bottom: 20px;
}
.social {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.social .meta {
  float: left;
  display: flex;
  margin-right: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-facebook.svg);
}
.social .meta.service_twitter {
  background-image: url(/images/icon-twitter.svg);
}
.social-text {
  float: left;
  font-size: 18px;
  line-height: 1.2;
}
#services {
  float: left;
  width: 100%;
}
#services .meta {
  float: left;
  font-size: 24px;
  line-height: 1.25;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0 10px;
}
#services .meta:first-child {
  margin-left: 0;
}
#services .meta:last-child {
  margin-right: 0;
}
#services .meta:hover,
#services .meta:focus {
  color: #433d39;
}
.toplink {
  position: fixed;
  bottom: -100px;
  right: 4.6875vw;
  width: 80px;
  height: 80px;
  margin-right: -40px;
  border: 2px solid #0294B3;
  border-radius: 80px;
  background-color: #fff;
  background-size: 40px 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/toplink.svg);
  z-index: 1999;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.toplink.cb-goto-top-visible {
  bottom: 4.6875vw;
  margin-bottom: 20px;
}
.root-batch-width {
  float: left;
  width: 100%;
  display: none;
  background-image: linear-gradient(to right, #F0EEED, #fff);
}
.cb-layout3 .root-batch-width {
  display: block;
}
.root-batch-text {
  display: block;
  padding: 2.34375vw;
  font-size: 2.421875vw;
  line-height: 1.27777778;
  font-family: 'Noto Serif', helvetica, sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.batch {
  float: right;
  position: absolute;
  right: -60px;
  top: -200px;
  z-index: 2;
  width: 350px;
  height: 350px;
  background: #AFCA05;
  border: 30px solid #fff;
  border-radius: 500000px;
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  transform: rotate(-10deg);
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
  display: none;
  color: #fff;
  text-align: center;
}
.c2 .batch {
  display: flex;
}
.batch__text {
  display: block;
  padding: 0 40px;
}
.batch__text--title {
  font-size: 36px;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  margin: 14px 0;
  padding: 0 30px;
  line-height: 1;
}
.batch__link {
  float: left;
  width: 52%;
  margin-top: 10px;
  align-self: center;
  border: 2px solid #fff;
  border-radius: 10000px;
  color: #fff !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  padding: 12px 0;
}
.batch__link:hover,
.batch__link:focus {
  background: #fff;
  color: #AFCA05 !important;
  animation: pulse-white 1s;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
}
@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 #fff;
  }
}
.batch--root {
  float: left;
  position: relative;
  z-index: 5;
  width: 47.65625vw;
  height: 47.65625vw;
  top: 0;
  margin-right: 0;
  display: flex;
  right: unset;
  border-width: 2.34375vw;
  margin-top: -23.828125vw;
  margin-left: -6.25vw;
  left: -60px;
  shape-outside: circle(29vw at 18vw 1vw);
}
.batch--root .batch__text {
  padding: 0 7.8125vw;
}
.batch--root .batch__text--title {
  font-size: 6.25vw;
  line-height: 1;
  margin: 2.34375vw 0;
  padding: 0 5.46875vw;
}
.batch--root .batch__link {
  width: 30%;
}
#root #tamaro-widget {
  background-color: transparent;
}
#root #tamaro-widget.tamaro-widget .test-mode-bar .main {
  background-color: transparent;
}
#root #tamaro-widget.tamaro-widget .widget-content .widget-block .widget-block-header .title .position {
  width: 24px;
  height: 24px;
}
#root #tamaro-widget,
#root #tamaro-widget.tamaro-widget .widget-content .widget-block .widget-block-header .title .position,
#root #tamaro-widget.tamaro-widget .widget-content .widget-block .widget-block-header .title .text,
#root #tamaro-widget.tamaro-widget .payment-amounts > .main .amounts .amount .main .code,
#root #tamaro-widget.tamaro-widget .payment-amounts .amount.custom-amount .form-block label,
#root #tamaro-widget.tamaro-widget.tamaro-bp-sm .payment-payment-methods > .main .payment-methods .payment-method .text,
#root #tamaro-widget-overlay.tamaro-widget .btn-text,
#tamaro-widget.tamaro-widget .btn-text {
  font-size: 14px;
  line-height: 1.71428571;
}
#root #tamaro-widget .footer-info {
  font-size: 10px;
  line-height: 1.4;
}
#expo {
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}
#slides {
  height: 100% !important;
  width: 100%;
  overflow: hidden;
}
#slides .slide {
  width: 100%;
  height: 100% !important;
}
.cb-layout1 #slides .slide:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}
#slides .slide a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  -moz-transform: translateY(0);
}
#expo div.link {
  display: none;
}
#over {
  z-index: 3000;
  height: 100% !important;
  background: rgba(2, 148, 179, 0.9);
}
#disp {
  z-index: 3001;
}
#root #disp.zoom {
  position: fixed;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  padding: 0 !important;
  height: 100% !important;
  box-sizing: border-box;
  border: 4.6875vw solid #fff;
}
#root #disp.zoom div.head {
  position: static;
  overflow: unset;
}
#root #disp.zoom h2 {
  display: none;
}
#root #disp.zoom div.ctrl {
  position: static;
}
#root #disp.zoom div.prev a,
#root #disp.zoom div.next a {
  position: fixed;
  z-index: 5;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: none !important;
  border: 2px solid #fff;
  box-sizing: border-box;
  opacity: 1;
  background-size: 16px 32px !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-image: url(/images/zoom-arrow-right.svg) !important;
}
#root #disp.zoom div.prev a {
  left: 4.6875vw;
  margin-left: 2.34375vw;
  background-image: url(/images/zoom-arrow-left.svg) !important;
}
#root #disp.zoom div.next a {
  right: 4.6875vw;
  margin-right: 2.34375vw;
}
#root #disp.zoom a.quit {
  position: fixed;
  z-index: 5;
  top: 4.6875vw;
  margin-top: 20px;
  right: 4.6875vw;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 100px;
  border: 2px solid #0294B3;
  margin-right: -40px;
  opacity: 1;
  background-size: 32px 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/close.svg);
}
#root #disp.zoom div.body {
  float: left;
  border-radius: 0;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  width: 100%;
  background: none;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  position: relative;
}
#root #disp.zoom div.body:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 16vw;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}
#root #disp.zoom div.body > div {
  float: left;
  position: static !important;
  width: 100%;
  height: 100%;
}
#root #disp.zoom div.body > div a {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
#root #disp.zoom div.body > div .cb-zoom-image {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
#root #disp.zoom div.foot {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  margin: 0;
  padding: 2.34375vw;
  padding-top: 0;
  background: none;
  box-sizing: border-box;
}
#root #disp.zoom div.foot fieldset {
  float: left;
  width: 100%;
  padding: 0;
}
#root #disp.zoom div.foot fieldset p {
  padding: 0;
  display: block;
  color: #fff;
  font-size: 1.5625vw;
  line-height: 1.1;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.02em;
}
#root .show-content #disp.zoom div.body {
  opacity: 1;
  transform: scale(1);
}
#root .ie11 #disp.zoom div.body {
  opacity: 1 !important;
  transform: scale(1) !important;
}
h1 {
  font-size: 80px;
  line-height: 1;
  font-weight: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
h2 {
  font-size: 24px;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.04em;
}
.farnorth h2,
.south h2 {
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.08em;
}
h3 {
  font-size: 28px;
  line-height: 1;
  letter-spacing: 0.0125em;
  font-weight: normal;
}
.south h3 {
  font-size: 48px;
  line-height: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
h4 {
  font-size: 24px;
  line-height: 1.41666667;
  font-family: 'Noto Serif', helvetica, sans-serif;
  font-weight: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
h5 {
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
}
strong {
  font-weight: normal;
  font-family: 'HelveticaNeueCondensedBold', helvetica, sans-serif;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
a {
  color: #7B7067;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #0294B3;
}
.norm a {
  text-transform: uppercase;
  white-space: nowrap;
}
.normcontent .area {
  margin-bottom: 60px;
}
#view .normcontent .area:empty {
  margin-bottom: 0 !important;
}
.normcontent .area .unit.flat:last-child,
.normcontent .area .unit.edge:last-child {
  margin-bottom: -60px;
}
.normcontent .area div.text {
  white-space: pre-wrap;
}
.normcontent .area.main {
  margin-bottom: 0;
}
div.side {
  margin-bottom: 60px;
}
#edit div.side {
  border-top: 3px dashed #ccc;
  border-bottom: 3px dashed #ccc;
}
#view div.side:empty {
  margin-bottom: 0 !important;
}
div.side div.unit {
  margin-top: 120px;
  margin-bottom: 5px;
}
div.side div.unit:first-child {
  margin-top: 45px;
}
div.farnorth {
  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  z-index: 3;
}
div.farnorth div.unit {
  width: 100%;
  margin: 0;
}
div.farnorth div.unit div.part {
  width: 100%;
  margin: 10px 0;
}
@media (max-width: 1023px) {
  .cb-layout2 div.farnorth {
    display: none;
  }
}
div.south {
  width: 100%;
  margin-top: 45px;
  margin-bottom: 1.09375vw;
}
div.south div.unit {
  width: 100%;
  margin: 0;
}
div.south div.unit div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
div.east {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
div.east a {
  color: #fff;
  text-transform: none;
}
div.east a:hover,
div.east a:focus {
  color: #AFCA05;
}
div.east div.unit {
  width: 100%;
}
div.east div.unit div.part {
  width: 100%;
  margin: 0;
}
div.east div.unit div.line hr {
  height: 0;
  border-color: #998F86;
}
div.east div.unit div.link a.open {
  background: none;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0;
  border: none;
  padding: 0;
  color: #CCC7C2;
  text-decoration: underline;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  text-align: left;
}
div.east div.unit div.link a.open:hover,
div.east div.unit div.link a.open:focus {
  animation: none;
  color: #fff;
}
div.east div.unit.wide .body {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 34px);
  margin: -17px;
}
div.east div.unit.wide .part {
  margin: 17px;
  width: calc(100% - 34px);
}
div.east div.unit.wide .part.tiny {
  width: calc(25% - 34px);
}
@media (max-width: 539px) {
  div.east div.unit.wide .part.tiny {
    width: calc(50% - 34px);
  }
}
.normcontent .area div.pure div.pict.tall {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.normcontent .area div.seam {
  background: #7B7067;
  margin-top: 60px;
  margin-bottom: 60px;
  color: #fff;
  padding-bottom: 45px;
}
.normcontent .area div.seam div.line hr {
  border-color: #998F86;
}
.normcontent .area div.seam div.pict.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.normcontent .area div.seam div.pict.tall:first-child {
  margin-top: 0;
}
.normcontent .area div.flat,
.normcontent .area div.edge {
  margin-top: 60px;
  margin-bottom: 60px;
}
.normcontent .area div.flat div.part,
.normcontent .area div.edge div.part {
  margin: 0;
  width: 100%;
}
.normcontent .area div.edge {
  margin-top: -60px;
}
div.side div.seam {
  margin: 0 !important;
}
div.side div.seam div.part {
  width: 100%;
  margin: 0;
}
#view div.side div.seam {
  overflow: hidden;
}
#view div.side div.seam div.text {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #fff;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#view div.side div.seam div.text a {
  color: #fff;
  white-space: normal;
  word-wrap: break-word;
  text-transform: none;
}
#view div.side div.seam div.text a:hover,
#view div.side div.seam div.text a:focus {
  color: #0294B3;
}
#view div.side div.seam div.pict {
  background: #000;
}
#view div.side div.seam div.pict img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#view div.side div.seam:hover div.text,
#view div.side div.seam:focus div.text {
  top: 0;
}
#view div.side div.seam:hover div.pict img,
#view div.side div.seam:focus div.pict img {
  opacity: 0.3;
}
div.south div.pure,
div.south div.edge {
  color: #fff;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
div.south div.pure div.body,
div.south div.edge div.body {
  height: 100%;
  box-sizing: border-box;
  padding: 15px 0;
}
div.south div.pure div.part,
div.south div.edge div.part {
  box-sizing: border-box;
  padding-left: 2.34375vw;
  padding-right: 2.34375vw;
  position: relative;
  z-index: 4;
}
div.south div.pure div.pict:first-child,
div.south div.edge div.pict:first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
}
div.south div.pure div.pict:first-child img,
div.south div.edge div.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
div.south div.pure div.link a.open,
div.south div.edge div.link a.open {
  border-color: #fff;
  color: #fff;
}
div.south div.pure div.link a.open:hover,
div.south div.edge div.link a.open:hover,
div.south div.pure div.link a.open:focus,
div.south div.edge div.link a.open:focus {
  background: #fff;
  color: #0294B3;
}
div.south div.edge div.pict:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
}
div.south div.seam {
  background: #0294B3;
  color: #fff;
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.south div.seam div.body {
  box-sizing: border-box;
  height: 100%;
  padding: 1.25vw 2.34375vw;
}
div.south div.seam div.link a.open {
  border-color: #fff;
  color: #fff;
}
div.south div.seam div.link a.open:hover,
div.south div.seam div.link a.open:focus {
  background: #fff;
  color: #0294B3;
  animation: none;
}
#view div.south div.seam div.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
#view div.south div.seam div.link a.open {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: 3.75vw 3.75vw;
  background-position: right 2.34375vw bottom 2.34375vw;
  background-repeat: no-repeat;
  background-image: url(/images/south-seam-2018-12-18.svg);
  border: none;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  border-radius: 0;
  padding: 0;
}
#view div.south div.seam:hover {
  background: #016880;
}
div.south div.flat {
  background: #CCC7C2;
}
div.south div.flat div.body {
  box-sizing: border-box;
  height: 100%;
  padding: 1.25vw 2.34375vw;
}
div.south div.flat div.text a.open {
  text-transform: none;
}
div.south div.flat div.link a.open {
  border-color: #7B7067;
  color: #7B7067;
}
div.south div.flat div.link a.open:hover,
div.south div.flat div.link a.open:focus {
  background: #7B7067;
  color: #fff;
}
div.unit.fold {
  margin-top: 60px;
  margin-bottom: 60px;
}
div.unit.fold + div.unit.fold {
  margin-top: -60px;
}
div.unit.fold .fold-toggle {
  display: block;
  text-decoration: none;
  border-bottom: 2px dotted #CCC7C2;
  padding: 16px 0 16px 50px;
  color: #7B7067;
  background-size: 30px 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: url(/images/fold-toggle-down.svg);
}
div.unit.fold .fold-toggle.fold-toggle--open {
  background-image: url(/images/fold-toggle-up.svg);
}
div.unit.fold div.less {
  float: left;
  width: 100%;
}
div.unit.fold div.less div.part {
  margin-top: 0;
  margin-bottom: 0;
}
div.unit.fold div.more {
  float: left;
  width: 100%;
  padding-bottom: 15px;
}
div.unit.fold div.ctrl {
  display: none;
}
div.form .cb-form-required {
  font-size: 16px;
}
div.form input.text,
div.form textarea {
  min-height: 50px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 5px 10px;
}
div.form input.submit {
  margin-bottom: 15px;
  background: none;
  display: block;
  border: 2px solid #0294B3;
  border-radius: 10000px;
  text-transform: uppercase;
  color: #0294B3;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  font-size: 18px;
  line-height: 1;
  padding: 5px 20px;
  min-height: 50px;
  letter-spacing: 0.1em;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background: #fff;
  animation: pulse 1s;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
}
div.form div.tick span.name {
  padding-bottom: 0;
}
div.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
}
div.form div.tick div.ctrl > div input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0;
}
div.form div.tick div.ctrl > div label {
  display: block;
  padding-left: 24px;
  margin: 0;
}
@media (max-width: 1023px) {
  .cb-layout3 div.south {
    display: flex;
    flex-wrap: wrap;
  }
  .cb-layout3 div.south .unit:nth-child(2) {
    order: 1;
  }
  .cb-layout3 div.south .unit:nth-child(3) {
    order: 2;
  }
  .cb-layout3 div.south .unit:nth-child(1) {
    order: 3;
  }
  .cb-layout3 div.south .unit:nth-child(4) {
    order: 4;
  }
  .cb-layout3 div.south .unit:nth-child(5) {
    order: 5;
  }
  .cb-layout3 div.south .unit:nth-child(6) {
    order: 6;
  }
  .cb-layout3 div.south .unit:nth-child(7) {
    order: 7;
  }
  .cb-layout3 div.south .unit:nth-child(8) {
    order: 8;
  }
  .cb-layout3 div.south .unit:nth-child(9) {
    order: 9;
  }
  .cb-layout3 div.south .unit:nth-child(11) {
    order: 10;
  }
  .cb-layout3 div.south .unit:nth-child(12) {
    order: 11;
  }
  .cb-layout3 div.south .unit:nth-child(10) {
    order: 12;
  }
}
.text-section {
  float: left;
  width: 100%;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.link a.open {
  float: left;
  display: block;
  text-align: center;
  border: 2px solid #0294B3;
  font-size: 18px;
  line-height: 1;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  color: #0294B3;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 20px;
  border-radius: 1000px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.link a.open:hover {
  background: #fff;
  animation: pulse 1s;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #0294B3;
  }
}
div.link.site + .link.site {
  margin-top: 0;
}
div.link.site a.open {
  text-align: left;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  font-size: 20px;
  line-height: 1.1;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  padding: 4px 0 4px 45px;
  background-size: 30px 30px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/link-extern.svg);
  text-decoration: underline;
}
div.link.site a.open:hover,
div.link.site a.open:focus {
  text-decoration: none;
}
div.load {
  background: none !important;
}
div.load + div.load {
  margin-top: 0;
}
div.load a.load {
  color: #0294B3;
  text-decoration: underline;
  font-size: 20px;
  line-height: 1.5;
  padding-left: 40px;
  background-size: 30px 30px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/load.svg);
}
div.load a.load:hover,
div.load a.load:focus {
  color: #016880;
}
div.pict a[title*='Zoom'] {
  display: block;
  position: relative;
  background: #000;
}
div.pict a[title*='Zoom'] img {
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.pict a[title*='Zoom'] .cb-zoom-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-plus-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.pict a[title*='Zoom']:hover img,
div.pict a[title*='Zoom']:focus img {
  opacity: 0.5;
}
div.pict a[title*='Zoom']:hover .cb-zoom-indicator,
div.pict a[title*='Zoom']:focus .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
div.pict img.zoom {
  display: none !important;
}
div.auth form {
  float: left;
  width: 100%;
}
div.auth fieldset {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
div.auth fieldset:first-child {
  margin-top: 0;
}
div.auth fieldset:last-child {
  margin-bottom: 0;
}
div.auth label.name {
  float: left;
  width: 100%;
  padding-bottom: 0.2em;
}
div.auth input.password,
div.auth input.text {
  float: left;
  width: 100%;
  background: #fff;
  min-height: 50px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 5px 10px;
}
div.auth .foot {
  float: left;
  width: 100%;
  margin: 30px 0 0 !important;
  padding: 0 !important;
}
div.auth input.submit {
  background: none;
  display: block;
  border: 2px solid #0294B3;
  border-radius: 10000px;
  text-transform: uppercase;
  color: #0294B3;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  font-size: 18px;
  line-height: 1;
  padding: 5px 20px;
  min-height: 50px;
  letter-spacing: 0.0225em;
}
div.auth input.submit:hover,
div.auth input.submit:focus {
  background: #0294B3;
  color: #fff;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.3em;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #7B7067;
  background-color: #fff;
  font-weight: normal;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  font-size: 20px;
  font-size: 2pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.3em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 20px;
  font-size: 2pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'HelveticaNeueCondensed', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #c3bdb8;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #0294B3;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #02a1c2;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #016a81;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .ship .chop {
  margin-top: 0;
}
.form .ship .chop input {
  float: left;
  background: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 1;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.3em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
.togglenavigation {
  position: fixed;
  top: 4.6875vw;
  right: 4.6875vw;
  margin-right: -40px;
  margin-top: 20px;
  width: 80px;
  height: 80px;
  border: 2px solid #0294B3;
  border-radius: 80px;
  background: #fff;
  z-index: 2001;
  box-sizing: border-box;
  cursor: pointer;
}
.tline {
  position: absolute;
  left: 50%;
  width: 44px;
  height: 2px;
  margin-left: -22px;
  background: #0294B3;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform-origin: 50% 50%;
}
.tline--1 {
  top: 27px;
  transition: all 0.4s 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .tline--1 {
  transition: all 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
  opacity: 0;
}
.tline--2,
.tline--3 {
  top: 50%;
  margin-top: -1px;
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: rotate(-45deg);
}
.tline--4 {
  bottom: 27px;
  transition: all 0.4s 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .tline--4 {
  transition: all 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
  opacity: 0;
}
.ttext {
  position: absolute;
  right: 100%;
  top: 50%;
  margin-right: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  opacity: 0;
  transform: translateY(-50%) scale(0);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.ttext.ttext--open {
  color: #0294B3;
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
body.cb-toggle-target-active .ttext.ttext--open {
  opacity: 0;
  transform: translateY(-50%) scale(0);
}
body.cb-toggle-target-active .ttext.ttext--close {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 0;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 4.6875vw;
  background: #0294B3;
  z-index: 2000;
  transition: max-height 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .navigation {
  max-height: 100%;
  overflow-y: auto;
  padding: 4.6875vw;
}
.navicontent {
  float: left;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 2.34375vw;
  opacity: 0;
  transform: translateY(-200px);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .navicontent {
  transition: all 0.6s 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
  opacity: 1;
  transform: translateY(0);
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
div.sub1 > .item {
  margin: 0.78125vw 0;
}
div.sub1 > .item.init {
  margin-top: 0;
}
div.sub1 > .item.exit {
  margin-bottom: 0;
}
div.sub1 > .item > .menu {
  float: left;
  color: #fff;
  font-size: 2.1875vw;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.015em;
}
@media (min-width: 1024px) {
  div.sub1 > .item > .menu:hover,
  div.sub1 > .item > .menu:focus {
    color: #61B8CA;
  }
}
div.sub1 > .item > .menu.here {
  color: #61B8CA;
}
div.sub2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
div.sub2 > .item > .menu {
  float: left;
  color: #fff;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  div.sub2 > .item > .menu:hover,
  div.sub2 > .item > .menu:focus {
    color: #61B8CA;
  }
}
div.sub2 > .item > .menu.here {
  color: #61B8CA;
}
#naviservices {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
#naviservices .meta {
  float: left;
  margin: 0.78125vw 0;
  font-size: 2.1875vw;
  font-family: 'HelveticaNeueCondensedBlack', helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  color: #61B8CA;
}
#naviservices .meta:first-child {
  margin-top: 0;
}
#naviservices .meta:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  #naviservices .meta:hover,
  #naviservices .meta:focus {
    color: #fff;
  }
}
#head {
  width: 89.65517241%;
  margin-left: 5.17241379%;
  margin-right: 5.17241379%;
  margin-top: 108px;
  margin-bottom: 60px;
}
.wrapper__part {
  width: 50%;
  box-sizing: border-box;
  border: 4.6875vw solid #fff;
  min-height: 100vh;
}
.cb-layout3 .wrapper__part {
  width: 100%;
  min-height: 0;
}
.wrapper__part--fix {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border-width: 4.6875vw 0 4.6875vw 4.6875vw;
}
.cb-layout3 .wrapper__part--fix {
  position: relative;
  height: auto;
  border-width: 4.6875vw 4.6875vw 0 4.6875vw;
}
.wrapper__part--scroll {
  float: right;
  border-width: 4.6875vw 4.6875vw 4.6875vw 0;
  background-image: linear-gradient(to right, #F0EEED, #fff);
}
.cb-layout3 .wrapper__part--scroll {
  float: left;
  background: none;
  border-width: 0 4.6875vw 4.6875vw 4.6875vw;
}
.footcontent {
  box-sizing: border-box;
  padding: 0 2.34375vw;
}
@media (min-width: 1024px) {
  .cb-layout3 .wrapper {
    font-size: 1.5625vw;
  }
  .cb-layout3 .social .meta {
    margin-right: 1.5625vw;
    width: 3.125vw;
    height: 3.125vw;
  }
  .cb-layout3 .social-text {
    font-size: 1.40625vw;
  }
  .cb-layout3 h1 {
    font-size: 6.25vw;
  }
  .cb-layout3 h2 {
    font-size: 1.875vw;
  }
  .cb-layout3 .farnorth h2,
  .cb-layout3 .south h2 {
    font-size: 1.5625vw;
  }
  .cb-layout3 h3 {
    font-size: 2.1875vw;
  }
  .cb-layout3 .south h3 {
    font-size: 3.75vw;
  }
  .cb-layout3 h4 {
    font-size: 1.875vw;
  }
  .cb-layout3 div.unit.fold {
    margin-top: 4.6875%;
    margin-bottom: 4.6875%;
  }
  .cb-layout3 div.unit.fold + .cb-layout3 div.unit.fold {
    margin-top: -4.6875%;
  }
  .cb-layout3 div.unit.fold .fold-toggle {
    padding: 1.25vw 0 1.25vw 3.90625vw;
    background-size: 2.34375vw 2.34375vw;
  }
  .cb-layout3 div.unit.fold div.more {
    padding-bottom: 1.171875%;
  }
  .cb-layout3 div.link a.open {
    font-size: 1.40625vw;
    padding: 1.25vw 1.5625vw;
  }
  .cb-layout3 div.load {
    background: none !important;
  }
  .cb-layout3 div.load + .cb-layout3 div.load {
    margin-top: 0;
  }
  .cb-layout3 div.load a.load {
    font-size: 1.5625vw;
    padding-left: 3.125vw;
    background-size: 2.34375vw 2.34375vw;
  }
}
.normcontent .area {
  width: 100%;
}
.normcontent .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.normcontent .area .foot {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
}
.normcontent .area .part {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
  width: 89.65517241%;
}
.normcontent .area .tiny {
  width: 39.65517241%;
}
.normcontent .area > .slim {
  width: 50%;
}
.normcontent .area > .slim .foot,
.normcontent .area > .slim .part {
  margin-right: 10.34482759%;
  margin-left: 10.34482759%;
}
.normcontent .area > .slim .part {
  width: 79.31034483%;
}
.normcontent .area > .slim .tiny {
  width: 79.31034483%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
}
.side .part {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
  width: 89.65517241%;
}
.side .tiny {
  width: 39.65517241%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 10.34482759%;
  margin-left: 10.34482759%;
}
.side > .slim .part {
  width: 79.31034483%;
}
.side > .slim .tiny {
  width: 79.31034483%;
}
div.normcontent .area {
  width: 100%;
}
div.side {
  width: 100%;
}
div.farnorth {
  padding: 0 2.34375vw 1.5625vw 2.34375vw;
}
div.south {
  width: 102.4137931%;
  margin-left: -1.20689655%;
}
div.east {
  width: 105.76923077%;
  margin-left: -2.88461538%;
}
.cb-layout3 div.east {
  width: 102.72727273%;
  margin-left: -1.36363636%;
}
.normcontent .area div.flat.wide div.tiny,
.normcontent .area div.edge.wide div.tiny {
  width: 50%;
}
div.south div.unit {
  margin: 1.17845118%;
  width: 64.30976431%;
  height: 59.609375vw;
}
div.south div.unit.slim {
  width: 30.97643098%;
  height: 28.75vw;
}
div.east div.unit {
  margin: 15px 2.72727273%;
  width: 94.54545455%;
}
div.east div.unit.slim {
  width: 44.54545455%;
}
div.east div.unit.seam {
  width: auto;
}
.cb-layout3 div.east div.unit {
  margin: 1.171875vw 1.32743363%;
  width: 97.34513274%;
}
.cb-layout3 div.east div.unit.slim {
  width: 30.61946903%;
}
.cb-layout3 div.east div.unit.seam {
  width: auto;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -300px;
  width: 600px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */